import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import LinktoFormButton from "../components/LinktoFormButton"
import SplitSection from "../components/SplitSection"
import TextWithLogo from "../components/TextWithLogo"
import TextWoLogo from "../components/TextWoLogo"
import Footer from "../components/footer"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default class About extends React.Component {
    componentDidMount() {
        const body = document.querySelector("body")
        body.style.overflow = "unset"
    }
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        datoCmsAbout {
                            aboutTitle
                            aboutParagraph
                            aboutImage {
                                gatsbyImageData
                            }
                            aboutMemberOneName
                            aboutMemberOneDesignation
                            aboutMemberOneDescription
                            aboutMemberTwoName
                            aboutMemberTwoDesignation
                            aboutMemberTwoDescription
                            aboutMemberThreeName
                            aboutMemberThreeDesignation
                            aboutMemberThreeDescription
                            aboutBannerHeading
                            useLinkInstead
                            link
                        }
                    }
                `}
                render={({ datoCmsAbout }) => (
                    <Layout>
                        <Seo title='About' />
                        <Header className='black w-b' isLink={datoCmsAbout.useLinkInstead} link={datoCmsAbout.link} />
                        <div data-scroll-container id='scroll-container' className='data-scroll-container'>
                            <div className='h-50'></div>
                            <div className='back-white '>
                                <SplitSection
                                    className=''
                                    right={
                                        <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                                            <GatsbyImage alt='' image={datoCmsAbout.aboutImage.gatsbyImageData} className='w-100-p h-100-p' layout='fullWidth' placeholder='dominantColor' />
                                        </div>
                                    }
                                >
                                    <TextWithLogo removeLink title={datoCmsAbout.aboutTitle} textClassName='alt' text={datoCmsAbout.aboutParagraph} className='p-t-140'></TextWithLogo>
                                </SplitSection>

                                <div className='b-t-1-b '>
                                    <div className={`back-white grid grid-3  w-100-p-b w-100-p r-c-l relative  ma-h-100vh-50 flex-grow-1   stack`}>
                                        <div className={` p-40 flex flex-r al-start min-h-100vh  w-100-p-b b-b-1-br`}>
                                            <div data-scroll data-scroll-call='fade-in' className=' o-0-5 h-100-p w-100-p' data-scroll-offset='30%'>
                                                <TextWoLogo divClass='min-h-420 mta' isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? "/contact"} classTitle='m-b-240-br mba' classLink='m-b-0 display-none flex-d' title={datoCmsAbout.aboutMemberOneName} subTitle={datoCmsAbout.aboutMemberOneDesignation} text={datoCmsAbout.aboutMemberOneDescription} />
                                            </div>
                                        </div>
                                        <div className={` p-40 ma-h-100vh-50 b-l-s-50 d-n-b b-b-1-br w-100-p-b c-d-n-b`}>
                                            <div data-scroll data-scroll-call='fade-in' className='o-0-5 h-100-p w-100-p' data-scroll-offset='30%'>
                                                <TextWoLogo divClass='min-h-420 mta' isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? "/contact"} classTitle='m-b-240-br mba' removeLink title={datoCmsAbout.aboutMemberTwoName} subTitle={datoCmsAbout.aboutMemberTwoDesignation} text={datoCmsAbout.aboutMemberTwoDescription} />
                                            </div>
                                        </div>
                                        <div className={` p-40 ma-h-100vh-50 b-l-s-50 d-n-b w-100-p-b c-d-n-b`}>
                                            <div data-scroll data-scroll-call='fade-in' className='o-0-5 h-100-p w-100-p' data-scroll-offset='30%'>
                                                <TextWoLogo divClass={'min-h-420 mta'} isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? "/contact"} classTitle='m-b-240-br mba' removeLink title={datoCmsAbout.aboutMemberThreeName} subTitle={datoCmsAbout.aboutMemberThreeDesignation} text={datoCmsAbout.aboutMemberThreeDescription} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-t-120 p-b-80 p-t-60-br p-b-60-br  flex flex-center-h b-t-1-b flex-c '>
                                    <div className='flex flex-center-h'>
                                        <h1 className='heading-0'>{datoCmsAbout.aboutBannerHeading}</h1>
                                    </div>
                                    <div className='flex flex-center-h p-t-40'>
                                        <LinktoFormButton isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? "/contact"} text=' I’M INTERESTED ' className='m-b-0' />
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </Layout>
                )}
            />
        )
    }
}
